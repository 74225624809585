<template>
	<div id="orderList">
		<div class="el-content mb12">
			<div class="kd-pacel">搜索参数</div>
			<div class="mb12">
				<span class="f12">订单状态：</span>
				 <a-radio-group v-model:value="search.order_status" button-style="solid"  @change="getOrderList(1,info.limit)">
					<a-radio-button value="-1">全 部</a-radio-button>
					<a-radio-button value="0">待支付</a-radio-button>
					<a-radio-button value="1">待分配</a-radio-button>
					<a-radio-button value="2">认养中</a-radio-button>
					<a-radio-button value="3">配送中</a-radio-button>
					<a-radio-button value="4">已完成</a-radio-button>
					<a-radio-button value="5">已退款</a-radio-button>
					<a-radio-button value="6">已取消</a-radio-button>
					<a-radio-button value="7">回收站</a-radio-button>
				</a-radio-group>
			</div>
			<div class="mb12">
				<span class="f12">下单时间：</span>
				<a-range-picker v-model:value="search.searchTime"/>
			</div>
			<div class="mb12">
				<span class="f12">关 键 字 ：</span>
				<a-input v-model:value="search.key" style="width:330px;">
					<template #addonBefore>
						<a-select v-model:value="search.selectType" style="width: 130px">
							<a-select-option value="1">订单编号</a-select-option>
							<a-select-option value="2">收件人姓名</a-select-option>
							<a-select-option value="3">收件人电话</a-select-option>
						</a-select>
					</template>
				</a-input>
			</div>
			<a-space style="margin-left: 60px;">
				<a-button type="primary" @click="getOrderList(1,info.limit)">查询</a-button>
				<!-- <a-button v-has="{action:'wxapp_adopt_export',plat:isShop}" @click="exportAdoptOrder">导出待发货订单</a-button> -->
				<com-upload-btn :custom-upload="true" btnText="批量发货" @uploadChange="uploadChange"></com-upload-btn>
			</a-space>
		</div>
		<div class="el-content">
			<div class="kd-pacel">列表</div>
			<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'商品信息',dataIndex:'goods',slots:{customRender:'goods'}},
				{title:'订单信息',dataIndex:'order',slots:{customRender:'order'}},
				{title:'买家',dataIndex:'user',slots:{customRender:'user'}},
				{title:'支付',dataIndex:'pay',slots:{customRender:'pay'}},
				{title:'状态',dataIndex:'status',slots:{customRender:'status'}},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" :row-selection="{ selectedRowKeys: exportIds, onChange:(e)=>{exportIds = e}  }">
				<template #goods="{record}">
					<div style="display: flex;margin-bottom: 10px;">
						<a-image :src="record.adopt.cover" style="width: 60px;height: 60px;"/>
						<div style="margin-left: 8px;">
							<div>{{record.adopt.name}}</div>
							<div>￥{{record.adopt.price}}</div>
							<div>x {{record.buy_count}}{{record.adopt.unit}}</div>
						</div>
					</div>
				</template>
				<template #order="{record}">
					<div><span class="f12">ID：</span>{{record.id}}</div>
					<div><span class="f12">订单编号：</span>{{record.order_number}}</div>
					<div><span class="f12">下单时间：</span>{{record.create_time}}</div>
				</template>
				<template #user="{record}">
					<div v-if="record.account"><span class="f12">昵称：</span>{{record.account.nickname}}</div>
					<div><span class="f12">姓名：</span>{{record.name}}</div>
					<div><span class="f12">电话：</span>{{record.mobile}}</div>
				</template>
				<template #pay="{record}">
					<div><span class="f12">订单总价：</span><span class="kdred">{{record.total_price}}元</span></div>
					<div><span class="f12">会员折扣：</span><span class="kdred">-{{record.discount}}元</span></div>
				</template>
				<template #status="{record}">
					<a-tag :color="$status.adoptOrderStatus(record,true)">{{$status.adoptOrderStatus(record)}}</a-tag>
				</template>
				<template #action="{record}">
					<a-space>
						<span  v-has="{action:'wxapp_adopt_order_remark',plat:isShop}">
							<com-set-remark 
								btn-text="备注" 
								btn-type="primary"
								remark-type="adopt-order"
								postApi="doSomethingByOrder" 
								:remarkValue="record.manager_remark"
								:params="{
									pk:record.id,
									type:5 ,
									order_type:10
								}">
							</com-set-remark>
						</span>
						<router-link :to="{path:isShop==0 ? '/adopt/orderDetail':'/many_store/adopt/orderDetail',query:{order_id:record.id}}">
							<a-button type="primary" size="small" 
								v-has="{action:'/adopt/orderDetail'}">详情
							</a-button>
						</router-link>
						<a-button type="danger" size="small" 
							@click="handleAdoptOrder(record.id,2,'确认将该订单移入回收站吗？')" 
							v-if="$status.checkAdoptOrderBtn(record,'inrecycle')">移入回收站
						</a-button>
						<a-button type="danger" size="small" 
							@click="handleAdoptOrder(record.id,2,'确认将该订单移出回收站吗？')" 
							v-if="$status.checkAdoptOrderBtn(record,'outrecycle')">移出回收站
						</a-button>
						<a-button type="danger" size="small"
							@click="handleAdoptOrder(record.id,6,'确认删除该订单吗？')" 
							v-if="$status.checkAdoptOrderBtn(record,'delete')">删除
						</a-button>
						<a-button type="danger" size="small"
							@click="handleAdoptOrder(record.id,'cancel','确认取消该订单吗?')" 
							v-if="$status.checkAdoptOrderBtn(record,'cancel')">取消订单
						</a-button>
					</a-space>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getOrderList(info.page,e)}"
					@change="(e)=>{getOrderList(e,info.limit)}"
				/>
			</div>
		</div>
	</div>
</template>
<script>
import comSetRemark from '@/components/miniapp/com-set-remark.vue'
import comUploadBtn from '@/components/public/com-upload-btn.vue'
import { reactive, toRefs } from 'vue'
import adoptModel from '@/api/adopt.js'
export default{
	name:'page-order-list',
	components:{
		comSetRemark,
		comUploadBtn
	},
	props:{
		isShop:Number
	},
	setup(){
		const _d = reactive({
			search:{
				key:'',
				selectType:'1',
				searchTime:[],
				order_status:'-1',
			},
			info:{
				list:[],
				page:1,
				count:0,
				limit:10,
			},
			exportIds:[],
		})
		getOrderList(1,_d.info.limit)
		function getOrderList(page,limit){
			adoptModel.getAdoptOrder(page,limit,_d.search,res=>_d.info = {limit,...res})
		}
		const uploadChange = (e)=>adoptModel.adoptOrderBatchSend(e)
		const exportAdoptOrder = ()=>adoptModel.exportAdoptOrder(_d.exportIds)
		const handleAdoptOrder = (id,type,title)=>adoptModel.handleAdoptOrder(id,type,title,()=>{
			getOrderList(_d.info.page,_d.info.limit)
		})
		return{
			...toRefs(_d),
			getOrderList,
			exportAdoptOrder,
			uploadChange,
			handleAdoptOrder
		}
	},
}
</script>
<style>
</style>
